<template>
  <div class="error-page">
    <h2>404界面</h2>
  </div>
</template>

<script>
export default {
  name: 'errorPage'
}
</script>

<style>

</style>
